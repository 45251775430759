import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../createSvgIcon";
import * as _Close3 from "@rsuite/icon-font/lib/application/Close";

var _Close2 = "default" in _Close3 ? _Close3.default : _Close3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _Close = _interopRequireDefault(_Close2); // Generated by script, don't edit it please.


var Close = (0, _createSvgIcon["default"])({
  as: _Close["default"],
  ariaLabel: "close",
  category: "application",
  displayName: "Close"
});
var _default = Close;
exports["default"] = _default;
exports = exports.default;
export default exports;